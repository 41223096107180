import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        // bgGradient="linear(to-r, teal.400, teal.600)"
        // backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        {t("Page Not Found")}
      </Text>
      <Text color={"gray.500"} mb={6}>
        {t("The page you're looking for does not seem to exist")}
      </Text>

      <Button
        onClick={() => navigate("/")}
        colorScheme="teal"
        // bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        // color="white"
        // variant="solid"
      >
        {t("Go to Home")}
      </Button>
    </Box>
  );
}
